<template>
  <spinner :isActive="isBusy"></spinner>
  <div v-if="user">
    Hello {{user.displayName}}
    <button class="button" @click="logout">Log out</button>
  </div>
  <div id="nav">
    | 
    <router-link to="/">Home</router-link> |
    <router-link to="/abilities">Ability List</router-link> |
    <router-link to="/abilityeditsidebyside">Ability</router-link> |
    <router-link to="/creatures">Creatures</router-link> |
    <router-link to="/creatureedit">Add Creature</router-link> |
    <!-- <router-link to="/creatureeditsidebyside">CreatureEditView</router-link> | -->
    <!-- <router-link to="/abilityeditsidebyside">AbilityEditView</router-link> | -->
  </div>
  <router-view/>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import Spinner from '@/components/Spinner'

export default {
  components: {
    Spinner,
  },
  data() {
    return {
    }
  },
  mounted() {
    this.authAction();
  },
  methods: {
    logout() {
      this.logout();
    },
    ...mapActions([
      'authAction',
      'logout'
    ])
  },
  computed: {
    ...mapGetters([
      'user',
      'isBusy',
    ]),
  },
  // created() {
  //   this.$store.commit('initializeStore');
  // },
}
</script>

<style lang="scss">


</style>

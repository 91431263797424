export default {
  methods: {
    roundToFixedPoint(value, precision) {
      
      
      if (value == false ||  value == "" || !value) {
        throw "roundToFixedPoint: Null or empty string";
      } else if (isNaN(parseFloat(value))) {
        throw "roundToFixedPoint: Not a number";
      } else {
        return parseFloat(value).toFixed(precision);
      }
    },
    arrayOfStringsWithCommas(arr) {
      let str = "";
      arr.forEach(val => str += val + ", ");
      return str.substring(0,str.length-2);
    },
    intOrNull(int) {
      if(isNaN(int) || int == null) {
        return null;
      } else {
        return parseInt(int);
      }
    },
    floatOrNull(float) {
      if(isNaN(float) || float == null) {
        return null;
      } else {
        return parseFloat(float);
      }
    },
  }
};

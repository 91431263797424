import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA9BVdbQgzr0_VnIDUH_RVvWn6GVrFG6HU",
    authDomain: "aethersteel-85fea.firebaseapp.com",
    databaseURL: "https://aethersteel-85fea-default-rtdb.firebaseio.com",
    projectId: "aethersteel-85fea",
    storageBucket: "aethersteel-85fea.appspot.com",
    messagingSenderId: "683177812213",
    appId: "1:683177812213:web:21a6297a885612c218a5bc",
    measurementId: "G-YG8PYJFVKK"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore()
if(location.hostname === "localhost") {
    db.useEmulator("localhost",9999);
}
const auth = firebase.auth()



// export utils/refs
export {
    db,
    auth,
}
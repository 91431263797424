import { createStore } from 'vuex'
// import localStoragePlugin from '@/plugins/localStorage'
import { db, auth } from '@/firebase'
import firebase from 'firebase/app'
import router from '@/router'


const provider = new firebase.auth.GoogleAuthProvider();

const store = createStore({
  // plugins: [localStoragePlugin],
  state() {
    return {
      abilities: null,
      lookups: null,
      creatures: null,
      user:null,
      isBusy:false,
    }
  },
  mutations: {
    // initializeStore() {
    //   const data = localStorage.getItem('pageState');
    //   if(data) {
    //     this.replaceState(Object.assign(this.state, JSON.parse(data)));
    //   }
    // },
    SET_LOOKUPS(state, val) {
      state.lookups = val;
    },
    SET_USER(state, val) {
      state.user = val;
    },
    SET_CREATURES(state, val) {
      state.creatures = val;
    },
    SET_CREATURE(state, {creature, docid}) {
      state.creatures[docid] = creature;
    },
    ADD_CREATURE(state, creature) {
      state.creatures[creature.docid] = creature;
    },
    DELETE_CREATURE(state, docid) {
      delete state.creatures[docid];
    },
    SET_ABILITIES(state, val) {
      state.abilities = val;
    },
    SET_ABILITY(state, {ability, docid}) {
      state.abilities[docid] = ability;
    },
    ADD_ABILITY(state, ability) {
      state.abilities = {...state.abilities, [ability.docid]: ability}
    },
    DELETE_ABILITY(state, docid) {
      delete state.abilities[docid];
    },
    SET_BUSY(state, isBusy) {
      state.isBusy = isBusy;
    }
  },
  actions: {
    fetchAbilities({commit}) {
      return db.collection('abilities').get().then(querySnapshot => {
        const abilities = {};
        querySnapshot.forEach(doc => {
          const ability = doc.data();
          ability.docid = doc.id;
          abilities[doc.id] = ability;
        })
        commit("SET_ABILITIES", abilities);
      })
    },
    fetchLookups({ commit }) {
      return db.collection('lookups').get().then(querySnapshot => {
        const lookups = {};
        querySnapshot.forEach(doc => {
          lookups[doc.id] = doc.data();
        })
        commit("SET_LOOKUPS", lookups);
      })
    },
    fetchCreatures({ commit }) {
      return db.collection('creatures').orderBy("name").get().then(querySnapshot => {
        const creatures = {};
        querySnapshot.forEach(doc => {
          const creature = doc.data();
          creature.docid = doc.id;
          creatures[doc.id] = creature;
        })
        commit("SET_CREATURES", creatures);
      })
    },
    addCreature({ commit }, creature) {
      if(creature.docid) {
        delete creature.docid;
      }
      return db.collection('creatures').add(creature).then((doc) => {
        creature.docid = doc.id;
        commit("ADD_CREATURE", creature);
      });
    },
    updateCreature({commit}, {creature, docid}) {
      if(creature.docid) {
        delete creature.docid;
      }
      return db.collection('creatures').doc(docid).set(creature).then(() => {
        creature.docid = docid;
        commit("SET_CREATURE",{creature, docid});
      });
    },
    deleteCreatureById({commit}, docid) {
      commit("DELETE_CREATURE", docid);
      return db.collection('creatures').doc(docid).delete();
    },
    duplicateCreatureById({commit}, docid) {
      let newCreature;
      return db.collection('creatures').doc(docid).get()
        .then((docToDup) => {
          newCreature = docToDup.data();
          newCreature.name = newCreature.name + " DUPLICATED";
          newCreature.createdAt = store.getters.fbTimestamp;
          newCreature.lastModified = store.getters.fbTimestamp;
          newCreature.uid = store.getters.user.uid;
          return db.collection('creatures').add(newCreature)
        }).then((newDoc) => {
          newCreature.docid = newDoc.id;
          commit("SET_CREATURE", newCreature);
          return newCreature;
        });

    },
    addAbility({ commit }, ability) {
      if (ability.docid) {
        delete ability.docid;
      }
      return db.collection('abilities').add(ability).then((doc) => {
        ability.docid = doc.id;
        commit("ADD_ABILITY", ability);
      });
    },
    updateAbility({ commit }, ability) {
      let docid = ability.docid;
      delete ability.docid;
      return db.collection('abilities').doc(docid).set(ability).then(() => {
        ability.docid = docid;
        commit("SET_ABILITY", {ability, docid});
      });
    },
    deleteAbilityById({ commit }, docid) {
      commit("DELETE_ABILITY", docid);
      return db.collection('abilities').doc(docid).delete();
    },
    duplicateAbilityById({ commit }, docid) {
      let newAbility;
      return db.collection('abilitys').doc(docid).get()
        .then((docToDup) => {
          newAbility = docToDup.data();
          newAbility.name = newAbility.name + " DUPLICATED";
          newAbility.createdAt = store.getters.fbTimestamp;
          newAbility.lastModified = store.getters.fbTimestamp;
          newAbility.uid = store.getters.user.uid;
          return db.collection('abilitys').add(newAbility)
        }).then((newDoc) => {
          newAbility.docid = newDoc.id;
          commit("SET_ABILITY", newAbility);
          return newAbility;
        });

    },
    async login() {
      // sign user in
      await auth.signInWithPopup(provider);
      router.push('/');
    },
    async logout() {
      // sign user in
      await auth.signOut();
      // this.commit('setCurrentUser', null);
      // fetch user profile and set in state
      router.push('/');
    },
    authAction({ commit }) {
      auth.onAuthStateChanged(user => {
        if (user) {
          commit("SET_USER", user);
        } else {
          commit("SET_USER", null);
        }
      });
    },
    toggleBusy({ commit }) {
      commit('SET_BUSY', !store.state.isBusy);
    },
    setBusy({ commit }) {
      commit('SET_BUSY', true);
    },
    setNotBusy({ commit }) {
      commit('SET_BUSY', false);
    },
    
  },
  modules: {
  },
  getters: {
    abilities(state) {
      return state.abilities
    },
    lookups(state) {
      return state.lookups;
    },
    creatures(state) {
      return state.creatures;
    },
    fbTimestamp() {
      return firebase.firestore.Timestamp.fromDate(new Date());
    },
    getAbilityByName: (state) => (abilityName) => {
      for(const docid in state.abilities) {
        if(state.abilities[docid].name == abilityName)
        return state.abilities[docid];
      }
    },
    getAbilityById: (state) => (docid) => {
      return state.abilities[docid];
    },
    getCreatureById: (state) => (docid) => {
      return state.creatures[docid];
    },
    user(state) {
      return state.user;
    },
    isBusy(state) {
      return state.isBusy;
    }
  },
})

export default store;
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/abilities',
    name: 'Abilities',
    component: () => import(/* webpackChunkName: "abilities" */ '../views/Abilities.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/abilityedit/:docid?',
    name: 'AbilityEdit',
    component: () => import(/* webpackChunkName: "abilityedit" */ '../views/AbilityEdit.vue'),
    meta: {
      requiresAuth: true
    },
    props:true,
  },
  {
    path: '/creatures',
    name: 'Creatures',
    component: () => import(/* webpackChunkName: "creatures" */ '../views/Creatures.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/creatureedit/:docid?',
    name: 'CreatureEdit',
    component: () => import(/* webpackChunkName: "creatureedit" */ '../views/CreatureEdit.vue'),
    meta: {
      requiresAuth: true
    },
    props: true,
  },
  {
    path: '/abilityeditsidebyside/:docid?',
    name: 'AbilityEditSideBySide',
    component: () => import(/* webpackChunkName: "abilityeditsidebyside" */ '../views/AbilityEditSideBySide.vue'),
    meta: {
      requiresAuth: true
    },
    props: true,
  },
  {
    path:'/login',
    name:'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue' )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if(requiresAuth && !auth.currentUser) {
    next('login');
  } else {
    next()
  }
})

export default router
